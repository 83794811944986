<template>
	<section class="sec-servicios-paqueteria">
		<div class="row">
			<div class="col-12 col-lg-auto my-auto">
				<p class="f-18 fr-bold text-black2">Servicios de paquetería</p>
			</div>
			<div class="col-12 col-lg-auto my-auto ml-lg-auto">
				<el-input v-model="search" placeholder="Buscar" autocomplete="nope" class="br-20" size="small" prefix-icon="el-icon-search" />
			</div>
			<div class="col-12 col-lg-auto my-auto">
				<button class="btn-general d-middle-center br-12 text-white h-36px w-137px" @click="openModalCreateService"> Crear servicio </button>
			</div>
		</div>
		<!-- listado servicios -->
		<div v-if="data.length" class="row mt-4">
			<div v-for="(item, index) in data.filter(el => el.nombre.toLowerCase().includes(search.toLowerCase()))" :key="index" class="col-auto mt-3" @click.stop="goToDetails(item.id)">
				<card-services :item="item"  @changeState="handleChangeState(item.estado)" />
			</div>
		</div>
		<div v-else class="d-middle-center text-center text-black2" style=" height:calc(100vh - 180px) ">
			<div>
				<div>
					<p>Aun no se han creado</p>
					<p>servicios de paqueteria</p>
				</div>
				<button class="btn-general d-middle-center br-12 text-white h-36px w-137px mx-auto mt-3" @click="openModalCreateService"> Crear servicio </button>
			</div>
		</div>
		<modal-create-service ref="refOpenModalCreateService" @update="getServicios()" />
		<modal-change-state ref="refOpenModalChangeState" />
	</section>
</template>

<script>
import Servicio from "~/services/serviciosPaqueteria";
export default {
	components: {
        cardServices: () => import('./components/cardServices'),
        modalCreateService: () => import('./partials/modalCreateService'),
        modalChangeState: () => import('./partials/modalChangeState'),
    },
	data() {
		return {
			search: '',
			data: []
		}
	},
	mounted(){
		this.getServicios();
	},
	methods: {
		openModalCreateService(){
			this.$refs.refOpenModalCreateService.toggle([])
		},
		handleChangeState(state){
			this.$refs.refOpenModalChangeState.toggle(state)
		},
		goToDetails(id){
			this.$router.push({ name: 'servicios.paqueteria.detalles', params: {id} })
		},
		async getServicios(){
			try {
				const { data } = await Servicio.getServicios();
				this.data = data.data
			} catch (e) {
				this.error_catch(e)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.sec-servicios-paqueteria{
	.text-black2{
		color: #000000;
	}
}
</style>
